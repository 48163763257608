import React, { createRef } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import parse from "html-react-parser";

export default (props) => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "mobile-menu" }) {
        menuItems {
          nodes {
            id
            path
            label
            target
            cssClasses
            childItems {
              nodes {
                id
                path
                label
                cssClasses
                childItems {
                  nodes {
                    id
                    path
                    label
                    cssClasses
                  }
                }
              }
            }
            parentId
          }
        }
      }
    }
  `);

  const menuItems = createRef();

  const expandPlusWrapper = (e) => {
    e.target.closest(".menu").classList.add("expand");
    const targetNav = e.target.getAttribute("data-target");
    const navsArray = Array.from(document.querySelectorAll(".sub-menu"));

    navsArray.map((nav) => {
      const navName = nav.getAttribute("data-target");
      if (navName === targetNav) {
        nav.classList.add("show");
      }
    });
  };

  const expandPlusWrapperChild = (e) => {
    e.target.closest(".sub-menu-parent").classList.add("expand-sub-menu");
    const targetNav = e.target.getAttribute("data-target");
    const navsArray = Array.from(document.querySelectorAll(".sub-menu"));

    navsArray.map((nav) => {
      const navName = nav.getAttribute("data-target");
      if (navName === targetNav) {
        nav.classList.add("show");
      }
    });
  };
  console.log(wpMenu);
  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <div className="nav">
      <ul className="menu" ref={menuItems}>
        {wpMenu.menuItems.nodes.map((menuItem, i) => {
          if (menuItem.parentId) {
            return null;
          }

          const path = menuItem?.connectedNode?.node?.path ?? menuItem.path;

          return (
            <li className={menuItem.cssClasses + " menu-item"} key={"link_" + menuItem.id}>
              <div className="link_wrap">
                <Link key={"link_" + menuItem.id} to={menuItem.target === "_blank" ? path : path}>
                  <span className="text">{menuItem.label}</span>
                  <span className="line"></span>
                </Link>
                <div className="plus-wrap">
                  <div onClick={expandPlusWrapper} data-target={menuItem.label.toLowerCase()} className="plus hover">
                    +
                  </div>
                </div>
              </div>

              {props.mobNav === true ? parse("</div>") : ""}
              <ul className="sub-menu sub-menu-parent" data-target={menuItem.label.toLowerCase()}>
                {menuItem.childItems.nodes &&
                  menuItem.childItems.nodes.map((subitem) => (
                    <li key={"sublink_" + subitem.id} className={subitem.cssClasses + " menu-item"}>
                      <div className="link_wrap-sub-menu">
                        <Link to={subitem.path} key={menuItem.id + "x"} onClick={() => props.data.closeMobNav()}>
                          <span className="text">{subitem.label}</span>
                        </Link>
                        <div className="plus-wrap">
                          <div onClick={expandPlusWrapperChild} data-target={`sub-${subitem.label.toLowerCase()}`} className="plus hover">
                            +
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>

              {menuItem.childItems.nodes &&
                menuItem.childItems.nodes.map((subitem) => (
                  <ul className="sub-menu sub-menu-child" data-target={`sub-${subitem.label.toLowerCase()}`}>
                    {subitem.childItems.nodes.map((child) => (
                      <li key={"sublink_" + child.id} className={child.cssClasses + " menu-item"}>
                        <Link to={child.path} key={menuItem.id + "x"} onClick={() => props.data.closeMobNav()}>
                          <span className="text">{child.label}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ))}
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};
